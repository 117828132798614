$(document).ready(function () {
    const $html = $('html');
    const $body = $('.booking-section-container');
    const $header = $('[data-plugin="sticky"]');
    const $h_height = $('.booking-section-container').innerHeight();

    //Sticky on mouse scroll
    if ($header.length > 0) {
        let position = $body.offset().top + $h_height;
        console.log($body.offset());

        if (window.pageYOffset >= position) {
            $header.addClass('sticky');
            // $html.addClass('sticky');
            // $body.css('margin-top', $h_height);
        }

        $(window).scroll(function () {
            if ($(document).scrollTop() > position) {
                $header.addClass('sticky');
                // $html.addClass('sticky');

                // $body.css('margin-top', $h_height);
            } else {
                $header.removeClass('sticky');
                // $html.removeClass('sticky');

                // $body.css('margin-top', 0);
            }
        });
    }
});