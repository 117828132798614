try {
    window.$ = window.jQuery = require('jquery');
    window.bootstrap = require('bootstrap');

    require('@popperjs/core');
    require('./components/Menu');
    require('./components/Slider');
    require('./components/ApartmentsSlider');
    require('./components/Marquee');
} catch (e) {
    // console.error(e);
}

$(function () {
    let $preloader = $('[data-preloader="load"]');

    let $close = $('[data-toggle="close"]');

    $close.on('click', function (e) {
        e.preventDefault();
        let $container = $close.closest('[data-container="close"]');

        $container.removeAttr('style').removeClass('wow slideInUp').addClass('animated slideOutDown');
        $container.css({
            "visibility": "visible",
            "animation-duration": "1s",
            "animation-delay": "0",
            "animation-name": "slideOutDown"
        });


        setTimeout(function () {
            $container.remove();
        }, 1000);
    });

    setTimeout(() => {
        $('body').addClass('loaded');
        $preloader.addClass('loaded');
    }, 100);

    function setMaximumContainerWidth() {
        let $container = $('.archive-apartment-container');

        if ($container.length > 0) {
            $container.parent().addClass('mw-100');
        }
    }

    setMaximumContainerWidth()
});
