import {Swiper, Navigation, Autoplay, Pagination, EffectFade} from "swiper";

$(function () {
    const modalSlider = new Swiper('#modal-slider', {
        modules: [Navigation, Autoplay],
        navigation: {
            nextEl: ".modal-button-next",
            prevEl: ".modal-button-prev",
        },
        slidesPerView: 1,
        spaceBetween: 32,
        loop: true,
        autoplay: {
            delay: 8000,
        },
        speed: 500,
        grabCursor: true,
        on: {
            afterInit: function(){
                let numOfSlides = this.wrapperEl.querySelectorAll(".swiper-slide:not(.swiper-slide-duplicate)").length;

                this.slideTo(numOfSlides, 1, false);
            }
        }
    });

    let $sliders = $('.images-slider');

    if ($sliders.length > 0) {
        $sliders.each(function () {
            let el = $(this);

            let $arrow_left = el.find('.images-button-prev')[0];
            let $arrow_right = el.find('.images-button-next')[0];

            new Swiper(el.find('.image-slider')[0], {
                modules: [Navigation, Autoplay],
                navigation: {
                    nextEl: $arrow_right,
                    prevEl: $arrow_left,
                },
                loop: true,
                autoplay: {
                    delay: 5000,
                },
                slidesPerView: 1,
                speed: 500,
                grabCursor: true,
            });
        });
    }

    let $opinions = $('.opinions-slider');

    if ($opinions.length > 0) {
        $opinions.each(function () {
            let el = $(this);

            let $arrow_left = el.find('.opinions-button-prev')[0];
            let $arrow_right = el.find('.opinions-button-next')[0];

            new Swiper(el.find('.image-slider')[0], {
                modules: [Navigation, Autoplay, Pagination, EffectFade],
                navigation: {
                    nextEl: $arrow_right,
                    prevEl: $arrow_left,
                },
                pagination: {
                    el: ".swiper-pagination",
                },
                loop: true,
                effect: "fade",
                fadeEffect: {
                    crossFade: true
                },
                autoplay: {
                    delay: 5000,
                },
                slidesPerView: 1,
                speed: 1000,
                grabCursor: true,
            });
        });
    }

    let $archive = $('.apartments-slider');

    if ($archive.length > 0) {
        $archive.each(function () {
            let el = $(this);

            let $arrow_left = el.find('.apartments-slider-button-prev')[0];
            let $arrow_right = el.find('.apartments-slider-button-next')[0];

            new Swiper(el.find('.apartment-slider')[0], {
                modules: [Navigation],
                navigation: {
                    nextEl: $arrow_right,
                    prevEl: $arrow_left,
                },
                loop: true,
                slidesPerView: 1,
                speed: 500,
                grabCursor: true,
            });
        });
    }
});